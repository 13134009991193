const data = [
  {
    num: "50",
    title: "게임인스, 인제대학교 AI융합대학과 MOU 체결로 협력 강화",
    date: "2024.11.06",
  },
  {
    num: "49",
    title: "2024 부산 백스코 잡페스티벌",
    date: "2024.10.24",
  },
  {
    num: "48",
    title: "2024 부산장애인진로취업박람회",
    date: "2024.09.10",
  },
  {
    num: "47",
    title: "2024 하계 휴가 안내",
    date: "2024.07.22",
  },
  {
    num: "46",
    title: "2024 고용우수기업 인증서 수여식",
    date: "2024.06.20",
  },
  {
    num: "45",
    title: "2024 서부산권일자리박람회",
    date: "2024.06.04",
  },
  {
    num: "44",
    title: " 부산북부고용센터 구인, 구직 만남의 날",
    date: "2024.05.29",
  },
  {
    num: "43",
    title: " 2024 해운대구 일자리박람회",
    date: "2024.05.27",
  },
  {
    num: "42",
    title: " 벤처기업확인기관장",
    date: "2024.05.13",
  },
  {
    num: "41",
    title: " 대한적십자 헌혈유공자 포장증",
    date: "2024.03.05",
  },
  {
    num: "40",
    title: " 2024 근로자 휴가지원 사업 참여 증서",
    date: "2024.02.26",
  },
  {
    num: "39",
    title: " 기타행사기획 및 대행서비스_직접생산확인증명서",
    date: "2024.02.19",
  },
  {
    num: "38",
    title: " 정보시스템 유지 관리_직접생산확인증명서",
    date: "2024.01.03",
  },
  {
    num: "37",
    title: " 가족친화인증서 취득",
    date: "2023.12.01",
  },
  {
    num: "36",
    title: " 2023년 여성 새로 일하기 센터 기업 협약",
    date: "2023.11.10",
  },
  {
    num: "35",
    title: " 2023년 동서대학교 취업박람회",
    date: "2023.11.08",
  },
  {
    num: "34",
    title: " 산학협력 체결서_정보영스피치",
    date: "2023.11.01",
  },
  {
    num: "33",
    title: " 2023년 기장군 일자리박람회",
    date: "2023.10.26",
  },
  {
    num: "32",
    title: " 2023년 연제구 일자리박람회",
    date: "2023.10.23",
  },
  {
    num: "31",
    title: " 사상여성인력개발센터 업무 협약",
    date: "2023.10.20",
  },
  {
    num: "30",
    title: " 대중문화예술기획업 등록 교육 이수",
    date: "2023.08.16",
  },
  {
    num: "29",
    title: " 동영상 제작 서비스_직접생산확인증명서",
    date: "2023.08.08",
  },
  {
    num: "28",
    title: " (주)게임인스 하계휴가 안내",
    date: "2023.07.25",
  },
  {
    num: "27",
    title: " (주)게임인스 TNT Studio의 촬영 스튜디오 사상점 오픈",
    date: "2023.07.21",
  },
  {
    num: "26",
    title: " 일 생활균형 일촌기업 사업 참여",
    date: "2023.06.22",
  },
  {
    num: "25",
    title: "한국건강가정진흥원 교육 이수",
    date: "2023.05.30",
  },
  {
    num: "24",
    title: " 2차 법정의무교육 진행",
    date: "2023.05.03",
  },
  {
    num: "23",
    title: " 센텀직업전문학교_업무 협약",
    date: "2023.04.26",
  },
  {
    num: "22",
    title: " M아카데미컴퓨터아트학원_산학 컨소시엄 협력",
    date: "2023.04.12",
  },
  {
    num: "21",
    title: " 2차 직무능력향상 교육",
    date: "2023.04.11",
  },
  {
    num: "20",
    title: " [보도자료] (주)게임인스, 씀씀이가 바른 기업 가입",
    date: "2023.04.10",
  },
  {
    num: "19",
    title: "부산디자인진흥원 인력양성사업 협약",
    date: "2022.04.04",
  },
  {
    num: "18",
    title: "파란일자리(1유형) 지원 사업 선정 및 협약",
    date: "2023.03.01",
  },
  {
    num: "17",
    title: "부산인재개발원 실무인재 양성훈련 협약",
    date: "2023.02.23",
  },
  {
    num: "16",
    title: "1차 법정의무교육 진행",
    date: "2023.02.17",
  },
  {
    num: "15",
    title: "근로자 휴가지원 사업 참여",
    date: "2023.02.02",
  },
  {
    num: "14",
    title: "1차 직무능력향상 교육",
    date: "2023.01.17",
  },
  {
    num: "13",
    title: "소상공인IP역량강화 교육 수료",
    date: "2022.11.24",
  },
  {
    num: "12",
    title: "일생활 균형 캠페인 참여",
    date: "2022.11.14",
  },
  {
    num: "11",
    title: "창업 아카데미 교육 이수",
    date: "2022.11.11",
  },
  {
    num: "10",
    title: "2022 신라대학교 취업박람회",
    date: "2022.11.03",
  },
  {
    num: "9",
    title: " 청년 일자리 지원 사업 참여",
    date: "2022.11.02",
  },
  {
    num: "8",
    title: "(재)부산인재개발원 부산IT교육센터와의 협약 체결",
    date: "2022.10.27",
  },
  {
    num: "7",
    title: " 2022 서부산권 채용박람회",
    date: "2022.10.13",
  },
  {
    num: "6",
    title: " 한국산업안전보건공단_2022년 사무직 종사자의 안전관리 교육 이수",
    date: "2022.09.15",
  },
  {
    num: "5",
    title: " 청년내일채움공제 참여",
    date: "2022.08.31",
  },
  {
    num: "4",
    title: " 개인정보보호위원회_(신)개인정보보호법 교육 수료",
    date: "2022.08.31",
  },
  {
    num: "3",
    title: " 통신판매업 신고",
    date: "2022.04.07",
  },
  {
    num: "2",
    title: " 기업부설연구소 인정",
    date: "2022.08.31",
  },
  {
    num: "1",
    title: " 중소기업 인증",
    date: "2022.08.31",
  },
];
export default data;
